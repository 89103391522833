import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../App.css";
import logo from "../assets/aviate.png";

function Footer() {
  return (
    <div
      style={{
        background: "#836BBD",
        paddingTop: "2.5rem"
      }}
      className="px-3 md:px-5"
      id="footer"
    >
      <img src={logo} className="w-24" />
      <div className="md:flex md:justify-between mt-3">
        <div className="md:w-1/3 mb-3 md:mb-0">
          Aviate is a job discovery and preparation platform for non-technical roles across top companies!
        </div>
        <div className="md:w-1/3">
          Aviate Education Private Limited, 91-Springboard, 2nd Floor, Opp
          Broadway Book Store, St-Inez, Panaji-Goa, 403001
        </div>
      </div>
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex items-center gap-3 mt-3 md:w-1/3">
          <a className="text-white no-underline" href="/terms-conditions">
            Terms & Conditions
          </a>
          <a className="text-white no-underline" href="/privacy-policy">
            Privacy Policy
          </a>
        </div>
        <a
          className="mt-3 md:w-1/3 no-underline text-white"
          rel="noreferrer"
          href="mailto:info@aviate.jobs"
          target="_blank"
        >
          info@aviate.jobs
        </a>
      </div>
      <div style={{textAlign: "center", paddingTop: "30px", paddingBottom: "10px", filter: "drop-shadow(2px 4px 6px black)"}}>
        Copyright © 2023 All rights reserved to Aviate Education pvt ltd.
      </div>
    </div>
  );
}

export default Footer;
